.book-container {
  width: 750px;
  margin: 0 auto;
}
.book-container h2 {
  font-family: "Interstate";
  text-align: center;
  margin-bottom: 25px;
  font-size: 40px;
  color: black;
}
.line {
  border: 3px solid #00a2c6;
  margin-bottom: 15px;
}
.card h3 {
  color: black;
  font-family: "Interstate";
}
.card {
  width: 325px;
  -webkit-box-shadow: 3px 3px 5px 6px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 3px 3px 5px 6px #ccc; /* Firefox 3.5 - 3.6 */
  box-shadow: 3px 3px 5px 6px #ccc;
  padding: 10px;
  margin-right: 30px;
  margin-bottom: 30px;
}
.card img {
  height: 500px;
  width: 100%;
}
.card h3 {
  margin-top: 12px;
  font-size: 30px;
  font-family: "Anton";
}
.short-title {
  padding-bottom: 35px;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.book-container a {
  color: #00a2c6;
}
@media (max-width: 480px) {
  .book-container {
    width: 400px;
  }
  .card {
    margin-left: 25px;
  }
}
@media (min-width: 480px) {
  .book-container {
    width: 500px;
  }
  .card {
    margin-left: 70px;
  }
}
@media (min-width: 500px) {
  .book-container {
    width: 500px;
  }
  .card {
    margin-left: 70px;
  }
}
@media (min-width: 650px) {
  .book-container {
    width: 650px;
  }
  .card {
    margin-left: 160px;
  }
}
@media (min-width: 850px) {
  .book-container {
    width: 800px;
  }
  .card {
    margin-left: 20px;
  }
}
