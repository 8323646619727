.loading-container {
    min-height: 600px;
}
.planete {
    position: relative;
    top: 200px;
    
}
.planetee {
    height: 300px;
    width: 300px;
    display: block;
    margin: 0 auto;
   
}
.textSI {
    position: absolute;
    top: 12.5%;
    right: 38%;
}
#pot {
    margin-top: 100px;
    position: relative;
    animation-name            : displaceContent;
    animation-duration        : 2s;
    animation-fill-mode       : forwards;
    
  }
  @keyframes displaceContent {
    from { transform : translateX(0em) }
    to   { transform : translateX(41%) } /* slide down to make room for advertisements */
}

