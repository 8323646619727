.episode-continer section {
  background-color: #00a2c6;
  width: 100%;
  height: 540px;
}
.episode-continer h2 {
  font-family: "Interstate";
  text-align: center;
  margin-bottom: 25px;
  font-size: 40px;
  color: black;
}
.player-container {
  width: 70%;
  margin: 0 auto;
}
.player-container p {
  text-decoration: underline;
  font-weight: 700;
  color: white;
}
.player-container img {
  width: 300px;
  height: 300px;
  margin-right: 40px;
}

.player-container h3 {
  color: white;
  font-family: "Martha Regular";
}
.player-container h2 {
  color: white;
  font-size: 35px;
  font-family: "Martha Regular";
}
.roww {
  display: flex;
  flex-direction: row;
}
.col {
  display: flex;
  flex-direction: column;
}
.episode-im img {
  height: 30px;
  width: 30px;
  margin-right: 13px;
}
.rhap_container {
  background-color: #00a2c6 !important;
  border: none !important;
  box-shadow: none !important;
}
.rhap_time {
  color: white;
}
.rhap_volume_indicator {
  background: white;
}
.rhap_progress-indicator,
.rhap_volume-indicator {
  background: white;
}
.audio svg {
  color: white;
  font-size: 30px;
}
.desc-container {
  width: 35%;
  margin: 0 auto;
}
.desc-container h5 {
  color: gray;
  font-size: 25px;
}
.desc-container p {
  font-size: 20px;
  line-height: 35px;
}
.desc-container a {
  font-weight: bold;
}
.desc-box {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 4; /* number of lines to show */
   -webkit-box-orient: vertical;
}
.h5-box {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
   -webkit-box-orient: vertical;
}
@media (max-width: 1600px) {
  .player-container h2 {
    font-size: 30px;
  }
}
@media (max-width: 1350px) {
  .player-container h2 {
    font-size: 25px;
  }
  .desc-container {
    width: 40%;
    margin: 0 auto;
  }
}
@media (max-width: 930px) {
  .audio {
    width: 600px;
  }
  .player-container {
    width: 90%;
  }
  .episode-continer section {
    width: 900px;
  }
  .desc-container {
    width: 80%;
    margin: 0 auto;
  }
}
@media (max-width: 630px) {
  .desc-container p {
    font-size: 28px;
  }
  .episode-continer section {
    max-width: 100%;
    height: 710px;
  }
  .player-container {
    width: 100%;
  }
  .player-container h2 {
    font-size: 18px;
    width: 98%;
    margin-left: 10px;
    margin-bottom: 25px;
  }
  .roww {
    display: flex;
    flex-direction: column;
  }
  .audio {
    width: 480px;
  }
  .perso-im {
    margin-left: 100px;
  }
  .player-container h3 {
    font-size: 14px;
    margin-left: 10px;
  }
  .player-container p {
    margin-left: 20px;
  }
  .episode-im {
    margin-left: 30px;
  }
}
@media (max-width: 480px) {
  .episode-continer section {
    max-width: 100%;
    height: 610px;
  }
  .player-container {
    width: 100%;
  }
  .player-container h2 {
    font-size: 18px;
    width: 98%;
    margin-left: 10px;
    margin-bottom: 25px;
  }
  .desc-container p {
    font-size: 18px;
  }
  .episode-im {
    margin-left: 30px;
  }
  .player-container h3 {
    font-size: 14px;
    margin-left: 10px;
  }
  .audio {
    width: 380px;
  }
  .roww img {
    width: 200px;
    height: 200px;
  }
  .col img {
    height: 20px;
    width: 20px;
  }
  .player-container p {
    margin-left: 20px;
  }
  .perso-im {
    margin-left: 100px;
  }
}
@media (max-width: 750px) {
  .episode-continer section {
    width: 100%;
  }
}
