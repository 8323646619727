.home-container {
  width: 1150px;
  margin: 0 auto;
}
.home-container img {
  width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-container h2 {
  text-align: center;
  font-size: 48px;
  font-family: "Noto Serif", serif;
  margin-bottom: 1px;
  font-weight: 100;
}
.home-container h3 {
  margin-top: 3px;
  font-family: "Interstate";
  text-align: center;
  margin-bottom: 25px;
  font-size: 34px;
  color: black;
}
.home-container p {
  margin: 0 auto;
  width: 900px;
  font-family: "Serif";
  text-align: justify;
  font-size: 16px;
  line-height: 22px;
}

/* RESPONSIVE */
@media (max-width: 480px) {
  .home-container img {
    width: 400px;
  }
  .home-container {
    width: 92%;
    margin: 0 auto;
  }
  .home-container p {
    width: 90%;
  }
}
@media (min-width: 480px) {
  .home-container {
    width: 480px;
  }
  .home-container p {
    width: 480px;
  }
  .home-container img {
    width: 480px;
  }
}
@media (min-width: 900px) {
  .home-container {
    width: 600px;
  }
  .home-container p {
    width: 600px;
  }
  .home-container img {
    width: 600px;
  }
}
@media (min-width: 1100px) {
  .home-container {
    width: 800px;
  }
  .home-container p {
    width: 800px;
  }
  .home-container img {
    width: 800px;
  }
}
