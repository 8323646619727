.apropos-container {
  width: 1100px;
  margin: 0 auto;
}
.apropos-container img {
  width: 1150px;
  height: 550px;
  object-fit: cover;
}
.apropos {
  width: 700px;
  margin: 0 auto;

  background-color: #fff;
  padding: 10px;
}
.apropos-container h2 {
  font-family: "Interstate";
  text-align: center;
  margin-bottom: 25px;
  font-size: 40px;
  color: black;
}
.apropos h3 {
  text-align: center;
  font-family: "Noto Serif";
  font-weight: 100;
  font-size: 45px;
  color: #00a2c6;
}
.apropos p {
  font-family: "Serif";
  font-size: 16px;
  line-height: 23px;
  text-align: justify;
  width: 800px;
  margin: 0 auto;
}
.apropos h4 {
  font-size: 29px;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 1px;
}
.author {
  display: flex;
  justify-content: flex-end;
}
.slick-slider {
  padding-top: 30px;
  display: block;
}
.slider-dis {
  position: relative;
}
.slick-arrow {
  display: hidden;
}
.center > img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.image-carousel img {
  margin: 0 auto;
}
.slick-slide div {
  float: left;
  outline: none;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* RESPONSIVE */

@media (max-width: 480px) {
  .apropos-container img {
    width: 400px;
  }
  .apropos-container {
    width: 400px;
  }
  .apropos {
    width: 340px;
  }
  .apropos p {
    width: 340px;
  }
  .apropos h4 {
    line-height: 30px;
  }
}
@media (min-width: 480px) {
  .apropos-container {
    width: 450px;
  }
  .apropos {
    width: 340px;
  }
  .apropos p {
    width: 340px;
  }
  .apropos-container img {
    width: 400px;
    margin-left: 1px;
  }
}

@media (min-width: 800px) {
  .apropos-container {
    width: 700px;
  }
  .apropos {
    width: 600px;
  }
  .apropos p {
    width: 600px;
  }
  .apropos-container img {
    width: 600px;
  }
}
@media (min-width: 1000px) {
  .apropos-container {
    width: 900px;
  }
  .apropos {
    width: 800px;
  }
  .apropos p {
    width: 800px;
  }
  .apropos-container img {
    width: 800px;
  }
}
@media (min-width: 480px) {
  .apropos-container img {
    margin-left: 30px;
  }
}
@media (min-width: 800px) {
  .apropos-container img {
    margin-left: 50px;
  }
}
