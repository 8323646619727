.cardd {
  position: relative;
  margin: 20px 0;
  width: 400px;
  height: 600px;
  background: #fff;
  transform-style: preserve-3d;
  transform: perspective(2000px);
  transition: 1s;
}

.cardd:hover {
  z-index: 1111;
  transform: perspective(2000px) rotate(0deg);
}
.sijechange-container h1 {
  color: black;
}
.cardd .img-container {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  box-sizing: border-box;
  transform-origin: left;
  z-index: 1;
  transition: 1s;
}

.cardd .img-container img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.cardd:hover .img-container {
  transform: rotateY(-135deg);
}

.cardd .cardd-details {
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
}
.cardd-details img {
  height: 600px;
}
.cardd .cardd-details h2 {
  margin: 0;
  padding: 0.5em 0;
  font-size: 20px;
  color: #ed363a;
}

.cardd .cardd-details p {
  margin: 0;
  padding: 0;
  line-height: 25px;
  font-size: 1.1em;
  color: black;
}
.book-co {
  width: 400px;
  margin: 0 auto;
}

@media (max-width: 480px) {
  .sijechange-container {
    width: 420px;
    margin: 0 auto;
  }
  .cardd {
    width: 240px;
    height: 370px;
    margin-left: 170px;
  }
  .cardd-details img {
    height: 310px;
    width: 180px;
  }
}
@media (max-width: 600px) {
  .sijechange-container {
    width: 90%;
    margin: 0 auto;
  }
  .cardd {
    width: 210px;
    height: 340px;
    margin-left: 180px;
  }

  .cardd-details img {
    height: 340px;
    width: 210px;
  }
}
@media (min-width: 600px) {
  .sijechange-container {
    width: 600px;
    margin: 0 auto;
  }
  .cardd {
    width: 260px;
    height: 400px;
    margin-left: 180px;
  }
  .cardd-details img {
    height: 400px;
    width: 260px;
  }
}
@media (min-width: 750px) {
  .sijechange-container {
    width: 600px;
    margin: 0 auto;
  }
  .cardd {
    width: 280px;
    height: 420px;
    margin-left: 180px;
  }
  .cardd-details img {
    height: 420px;
    width: 280px;
  }
}
@media (min-width: 900px) {
  .sijechange-container {
    width: 750px;
    margin: 0 auto;
  }
  .cardd {
    width: 320px;
    height: 490px;
    margin-left: 180px;
  }
  .cardd-details img {
    height: 490px;
    width: 320px;
  }
}

@media (min-width: 1050px) {
  .sijechange-container {
    width: 900px;
    margin: 0 auto;
  }
  .cardd {
    width: 380px;
    height: 580px;
    margin-left: 180px;
  }
  .cardd-details img {
    height: 580px;
    width: 380px;
  }
}
