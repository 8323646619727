.episode img {
  height: 30px;
}
.episode-container {
  width: 90%;
  margin: 0 auto;
}
.episode-container h2 {
  font-family: "Interstate";
  text-align: center;
  margin-bottom: 25px;
  font-size: 40px;
  color: black;
}
.episode-container h3 {
  text-align: center;
  font-size: 30px;
  font-family: "Noto Serif", serif;
  margin-bottom: 1px;
  font-weight: 100;
}
.episode-container h4 {
  text-align: center;
  font-size: 25px;
  font-family: "Noto Serif", serif;
  margin-bottom: 1px;
  font-weight: 100;
}
.podcast-logo {
  display: flex;
  flex-direction: row;
  width: 160px;
  margin: 0 auto;
}
.podcast-logo img {
  margin-right: 10px;
}

.description-podcast {
  font-family: "Serif";
  width: 800px;
  margin: 0 auto;
  line-height: 25px;
  font-size: 16px;
  text-align: justify;
  margin-top: 35px;
}
.podcast-box {
  width: 300px;
  cursor: pointer;
  margin-right: 30px;
  margin-bottom: 50px;
}

.podcast-box h5 {
  font-size: 23px;

  width: 100%;
  margin: 0 auto;
  font-family: "Martha Regular";
}
.podcast-box span {
  color: gray;
  font-family: "Martha Regular";
  font-size: 13px;
}

.podcast {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.itemsContainer {
  float: left;
  position: relative;
}
.podcast-box:hover .play {
  display: block;
}
.podcast-box:hover .image {
  -webkit-filter: brightness(70%);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.play {
  position: absolute;
  display: none;
  top: 35%;
  width: 60px;
  margin: 0 auto;
  left: 0px;
  right: 0px;
  z-index: 100;
}
.itemsContainer .play {
  height: 100px;
}
.itemsContainer .image {
  height: 300px;
  width: 300px;
}
@media (max-width: 1800px) {
  .podcast {
    width: 1000px;
  }
}
@media (max-width: 1400px) {
  .podcast {
    width: 1000px;
  }
}
@media (max-width: 750px) {
  .podcast {
    display: flex;
    justify-content: center;
  }
  .description-podcast {
    width: 80%;
  }
  .episode-container {
    width: 90%;
  }
}
@media (max-width: 600px) {
  .itemsContainer .image {
    width: 300px;
    height: 300px;
  }
  .itemsContainer {
    display: flex;
    justify-content: center;
  }
  .description-podcast {
    font-size: 22px;
    width: 80%;
    line-height: 28px;
  }
  .podcast-box p {
    font-size: 21px;
  }
}
@media (max-width: 480px) {
  .itemsContainer .image {
    width: 300px;
    height: 300px;
  }
  .podcast {
    width: 90%;
    margin: 0 auto;
  }
  .episode-container {
    width: 100%;
  }
  .podcast-box {
    margin-right: 1px;
  }
}
@media (max-width: 900px) {
  .description-podcast {
    width: 90%;
  }
}
