.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}
.helmet {
  height: 600px;
  margin-right: 60px;
  border: 5px solid black;
}
.formm {
  display: flex;
  justify-content: center;
}
input {
  font-family: "Interstate";
  font-size: 17px;
  border: none;
  margin-left: 20px;
  outline: none;
  width: 600px;
  border-bottom: 1px solid black;
}
.name {
  margin: 30px;
  padding-bottom: 20px;
  color: black;
}
.nom {
  margin: 30px;
  color: black;
  padding-bottom: 20px;
}
.email {
  color: black;
  margin: 30px;
  padding-bottom: 20px;
}
.tel {
  color: black;
  margin: 30px;
  padding-bottom: 20px;
}
::-webkit-input-placeholder {
  color: black;
}
.titre-cont {
  font-family: "Interstate";
  text-align: center;
  margin-bottom: 25px;
  font-size: 40px;
  color: black;
}
.souhaite {
  font-family: "Serif";
  font-weight: 500;
  color: black;
  font-size: 23px;
  margin: 0 auto;
  text-align: center;
  line-height: 38px;
}
textarea {
  font-family: "Interstate";
  font-size: 17px;
  margin-left: 30px;
  height: 40px;
  width: 600px;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  margin-bottom: 50px;
  margin-top: 20px;
  resize: none;
}
.envoy {
  font-family: "Interstate";
  font-size: 14px;
  border: none;
  background-color: black;
  color: white;
  width: 300px;
  height: 40px;
  outline: none;
  cursor: pointer;
}
.envoy:hover {
  background-color: #00a2c6;
  color: black;
}
.envoyer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
a {
  text-decoration: none;
  color: black;
}
@media (max-width: 1280px) {
  input {
    width: 500px;
  }
  textarea {
    width: 500px;
  }
}
@media (max-width: 480px) {
  input {
    width: 250px;
  }
  .titre-cont {
    font-size: 30px;
  }
  textarea {
    width: 250px;
  }
  .envoy {
    width: 250px;
    margin-right: 20px;
  }
  .row {
    display: flex;
    flex-direction: column;
  }
}
