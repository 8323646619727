.sijechange-container {
  width: 1150px;
  margin: 0 auto;
}
.sijechange-container img {
  width: 400px;
  height: 500px;
  -webkit-box-shadow: 3px 3px 5px 6px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 3px 3px 5px 6px #ccc; /* Firefox 3.5 - 3.6 */
  box-shadow: 3px 3px 5px 6px #ccc;
}
.sijechange-container h1 {
  text-align: center;
}

.sijechange-container a {
  color: #00a2c6;
}
.panel__image.panel__image--book {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 60px;
}
.books__book__img {
  width: 100%;
  max-width: 350px;
  will-change: transform;
  transform-origin: 0 100%;
  transform: rotate(-10deg);
  transition: transform 0.3s ease-out;
}
.books__book__img:hover {
  transform: rotate(0);
}
.books__book__image:hover {
  position: relative;
}
.author__image-wrapper::before,
.books__book__image::before {
  display: block;
  content: "";
  height: 20%;
  width: 45%;
  position: absolute;
  background-image: url(https://i.imgur.com/5udcDlp.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  top: 84%;
  left: 35%;
  right: 0;
  transition: all 0.2s ease-out;
  transform-origin: 30% 50%;
  pointer-events: none;
}

/* ESSAY */
@media (max-width: 480px) {
  .sijechange-container {
    width: 420px;
  }
}
