@font-face {
  font-family: "Interstate";
  src: local("Interstate"), url(./fonts/Interstate-Regular.woff) format("woff");
}

@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: local("Neue Haas Grotesk Display Pro")
      url(./fonts/SF-Pro-Display-Regular.otf),
    format("opentype");
}
@font-face {
  font-family: "Neue Haas Grotesk Display Pro Regular";
  src: local("Neue Haas Grotesk Display Pro Regular")
      url(./fonts/SF-Pro-Display-Regular.otf),
    format("opentype");
}
@font-face {
  font-family: "Neue Haas Grotesk Text";
  src: local("Neue Haas Grotesk Text") url(./fonts/SF-Pro-Text-Medium.otf);
}
@font-face {
  font-family: "Martha Regular";
  src: local("Martha Regular"),
    url(./fonts/Martha-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Ogg";
  src: local("Ogg"), url(./fonts/Ogg-Roman.woff2) format("woff2");
}
