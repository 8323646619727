header {
  width: 900px;
  height: 35px;
  color: white;
  margin: 0 auto;
  border-bottom: 2px solid black;
}
header ul {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  font-family: "Neue Haas Grotesk Display Pro";
}
header li {
  color: black;
}
header a {
  text-decoration: none;
}
header li:hover {
  color: gray;
  font-size: 15px;
}

/* RESPONSIVE */
@media (max-width: 900px) {
  header {
    width: 650px;
  }
}
@media (max-width: 650px) {
  header {
    width: 480px;
  }
  header li {
    font-size: 12px;
  }
}
@media (max-width: 480px) {
  header {
    width: 460px;
  }
  header li {
    font-size: 12px;
  }
  header {
    display: none;
  }
}
