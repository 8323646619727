footer {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.footer {
  display: flex;
  flex-direction: column;
}
.share {
  margin-left: 75px;
}
.footer p {
  font-size: 13px;
}
.share img {
  height: 30px;
}
.share button {
  outline: none;
  margin-right: 15px;
}
