.article-container {
  width: 1000px;
  margin: 0 auto;
}
.enparler h4 {
  font-family: "Interstate";
  text-align: center;
  margin-bottom: 25px;
  font-size: 40px;
  color: black;
}
.parler-card {
  background-color: #f8f8f8;
  width: 300px;
  height: 300px;
  cursor: pointer;
  margin-right: 30px;
  margin-bottom: 30px;
}
.card-parler-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.parler-card span {
  color: #00a2c6;
  font-size: 25px;
}
.parler-card div {
  text-align: center;
}
.parler-card h5 {
  text-align: center;
  font-size: 26px;
  width: 80%;
  margin: 0 auto;
}
.parler-card:hover h5 {
  font-size: 32px;
}
.parler-card:hover span {
  display: none;
}
@media (max-width: 1000px) {
  .article-container {
    width: 800px;
  }
}
@media (max-width: 800px) {
  .article-container {
    width: 600px;
  }
  .parler-card {
    margin-left: 50px;
    width: 200px;
    height: 400px;
  }
}
@media (max-width: 480px) {
  .article-container {
    width: 460px;
  }
  .article-container h4 {
  }
  .parler-card {
    width: 300px;
  }
}
@media (max-width: 700px) {
  .article-container {
    width: 480px;
  }
  .parler-card {
    width: 300px;
    margin-left: 70px;
  }
}
